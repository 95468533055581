// essentials
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withTheme, alpha, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import amplitude from 'amplitude-js';

// UI/UX
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// ICONS
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FolderIcon from '@material-ui/icons/Folder';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import RoomIcon from '@material-ui/icons/Room';
import StorageIcon from '@material-ui/icons/Storage';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import WifiIcon from '@material-ui/icons/Wifi';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

// MODULES
import { List as VirtualList, AutoSizer, Column, Table } from 'react-virtualized';
import numeral from 'numeral';
import moment from 'moment';
import throttle from 'lodash.throttle';
import { capitalCase } from 'change-case';

// OURS
import { Features as FEATURES, has } from '@premisedata/lib-features';
import { BarChart, ThumbsChart, MSPieChart, PhotoChart } from '@premisedata/iris-components';
import VideoChart from './components/VideoChart';
import { enlargePhoto as enlargePhotoAction, pushSnackbar as pushSnackbarAction } from '../../actions';
import { genericPost, API_HOST } from 'iris-api'; // eslint-disable-line import/no-unresolved
import WifiPaper from '../radio/components/WifiPaper';
import { LEFT_PANEL_WIDTH } from 'iris-config'; // eslint-disable-line import/no-unresolved
import { setNameSimilar as setNameSimilarAction } from './placesSlice';

const InteractiveIconButton = withStyles(
  (theme) => ({
    root: {
      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  }),
  { withTheme: true }
)(IconButton);

const useStyles = makeStyles((theme) => ({
  span: {
    display: 'block',
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  }
}));
const OverlayedInteractiveIconButton = (props) => {
  const { tooltip, icon, ...otherProps } = props;
  const classes = useStyles();
  return (
    <Tooltip placement="top" arrow={true} title={tooltip}>
      <span className={classes.span}>
        <InteractiveIconButton size="small" {...otherProps}>
          {icon}
        </InteractiveIconButton>
      </span>
    </Tooltip>
  );
};
OverlayedInteractiveIconButton.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.node
};

const styles = (theme) => ({
  buttonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'block',
    zIndex: 20,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tabPaper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    borderRadius: '0px 0px 4px 4px',
    overflowX: 'hidden'
  },
  imgPaper: {
    padding: theme.spacing(1),
    minHeight: 200
  },
  img: {
    display: 'block',
    height: '100%',
    minHeight: 200,
    maxHeight: Math.round((LEFT_PANEL_WIDTH / 1.77) * 2),
    width: '100%',
    margin: 'auto',
    objectFit: 'cover',
    zIndex: 10,
    cursor: 'pointer'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imgDate: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    padding: theme.spacing(1)
  },
  disableOutline: {
    outline: 0
  },
  photo: {
    objectFit: 'contain',
    cursor: 'pointer'
  },
  listItemIcon: {
    minWidth: 36
  },
  expandedListItem: {
    display: 'block',
    height: '50vh'
  },
  submissionPhoto: {
    height: 30,
    width: 30
  },
  submissionsRowHovered: {
    backgroundColor: '#ABEC43',
    color: theme.palette.common.black
  },
  submissionsRowSelected: {
    backgroundColor: '#308C9D',
    color: theme.palette.common.black
  }
});

const PeanutTab = withStyles(() => ({
  root: {
    minWidth: 50
  }
}))((props) => <Tab {...props} />);

function TabPanel(props) {
  const { children, value, index } = props;
  return value !== index ? null : children;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

class DetailPanel extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      activeTab: 0,
      disableValidationButtons: false,
      photoFailure: false
    };
    this.state = this.initialState;

    // BIND
    this.onValidationClicked = this.onValidationClicked.bind(this);
    this.onAutoValidationClicked = this.onAutoValidationClicked.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.enlargePhoto = this.enlargePhoto.bind(this);
    this.onSubmissionDetailsClicked = this.onSubmissionDetailsClicked.bind(this);
    this.onCopyCoords = this.onCopyCoords.bind(this);
    this.onSubmissionRowMouseOver = this.onSubmissionRowMouseOver.bind(this);
    this.classForSubmissionRow = this.classForSubmissionRow.bind(this);
    this.onSubmissionRowClicked = this.onSubmissionRowClicked.bind(this);
    this.filterPlacesBySimilarName = this.filterPlacesBySimilarName.bind(this);

    // THROTTLE
    this.onSubmissionRowMouseOver = throttle(this.onSubmissionRowMouseOver, 50);

    // XHR
    this._xhrAV = {};
    this._xhrSingleSubValidation = {};
    this._xhrUnknownValidation = {};

    // TABLE
    this.submissionsTable = [
      { k: 'sub_id', n: 'Id' },
      { k: 'name', n: 'Name' },
      { k: 'accuracy', n: 'Accuracy', a: 'right', f: (d) => numeral(d).format('0.00') }
    ];

    this.rowStyle = {
      cursor: 'pointer'
    };
  }
  componentWillUnmount() {
    // THROTTLE
    this.onSubmissionRowMouseOver.cancel();

    // XHR
    this._xhrAV.cancel && this._xhrAV.cancel();
    this._xhrSingleSubValidation.cancel && this._xhrSingleSubValidation.cancel();
    this._xhrUnknownValidation.cancel && this._xhrUnknownValidation.cancel();
  }
  componentDidUpdate(prevProps) {
    const { place: p, setNearbyWifiShown, nearbyWifiShown, selectedSubmission, hoverSubmission, submissions } = this.props;
    const { place: pp } = prevProps;
    if (p && pp && p.place_id !== pp.place_id) {
      // place has changed
      this.setState(this.initialState);
      if (nearbyWifiShown) setNearbyWifiShown(false);
    }
    if (submissions && selectedSubmission) {
      const idx = submissions.indexOf(selectedSubmission);
      if (this.state.selectedSubmissionIdx !== idx) {
        this.setState({ selectedSubmissionIdx: idx });
      }
    } else if (this.state.selectedSubmissionIdx) {
      this.setState({ selectedSubmissionIdx: null });
    }

    if (submissions && hoverSubmission) {
      const idx = submissions.indexOf(hoverSubmission);
      if (this.state.hoverSubmissionIdx !== idx) {
        this.setState({ hoverSubmissionIdx: idx });
      }
    } else if (this.state.hoverSubmissionIdx) {
      this.setState({ hoverSubmissionIdx: null });
    }
  }

  tabChange(_, activeTab) {
    this.setState({ activeTab });
    if (activeTab === 2 && has(this.props.user.active_features, FEATURES.RADIO)) {
      this.props.setNearbyWifiShown(true);
    } else if (this.props.nearbyWifiShown) {
      this.props.setNearbyWifiShown(false);
    }
    if (activeTab === 1) {
      amplitude.getInstance().logEvent('photos_viewed', {
        selection: this.props.place?.place_id
      });
    }
  }

  renderGraphics() {
    const { classes, place, enlargePhoto } = this.props;
    if (!place || !place.aggregations) return null;

    return Object.keys(place.aggregations).map((k) => {
      const d = place.aggregations[k];

      // skip empty objects
      if (!d || Object.keys(d).length === 0) {
        return null;
      }

      if (d.visualization) {
        let Component;
        const componentProps = {};
        if (d.visualization === 'bar') {
          Component = BarChart;
        } else if (d.visualization === 'hands') {
          Component = ThumbsChart;
        } else if (d.visualization === 'ms-pie') {
          Component = MSPieChart;
        } else if (d.visualization === 'photo') {
          if (d?.data?.[0]?.endsWith('mp4')) {
            Component = VideoChart;
          } else {
            Component = PhotoChart;
            componentProps.onPhotoClicked = (photo_url) => {
              enlargePhoto(photo_url);
            };
          }
        } else {
          // don't know what this is:
          return null;
        }

        // empty object:
        if ((Array.isArray(d.data) && d.data.length === 0) || Object.keys(d.data).length === 0 || Object.values(d.data).reduce((a, b) => a + b) === 0) {
          return null;
        }

        // render it:
        return (
          <React.Fragment key={'visualization' + k}>
            <Divider variant="middle" className={classes.divider} />
            <Component width={360} key={k} title={k} data={d.data} {...componentProps} />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={k}>
            <Divider variant="middle" className={classes.divider} />
            <BarChart width={360} key={k} title={k} data={d} />
          </React.Fragment>
        );
      }
    });
  }
  onValidationClicked() {
    const { onValidate, place, submissions } = this.props;
    if (submissions.length === 1) {
      this.setState({ disableValidationButtons: true });
      this._xhrSingleSubValidation.cancel?.();
      const body = { unknown: [], destroyed: [], associations: [{ place_id: place.place_id, submission_ids: submissions.map((d) => d.sub_id) }] };
      genericPost(`${API_HOST}/places/v0/place-harmonizer/validation`, body, this._xhrSingleSubValidation, (e) => {
        if (e) this.props.pushSnackbar({ type: 'error', message: 'failed to validate place, please try again later...' });
        else this.props.pushSnackbar({ type: 'success', message: `"${place.place_name}" marked as valid!` });
        this.setState({ disableValidationButtons: false });
      });
    } else {
      onValidate();
    }
  }

  onAutoValidationClicked() {
    const { place } = this.props;
    this.setState({ disableValidationButtons: true });
    this._xhrAV.cancel && this._xhrAV.cancel();
    genericPost(`${API_HOST}/places/v0/place-harmonizer/place_clique/${place.place_id}`, {}, this._xhrAV, (e, r) => {
      this.setState({ disableValidationButtons: false });
      if (e) {
        this.props.pushSnackbar({ type: 'error', message: 'Auto-Validation failed, please try again later...' });
      } else {
        this.props.pushSnackbar({ type: 'success', message: `Auto-Validation of "${place.place_name}" complete.` });
        this.props.onAutoValidate(r);
      }
    });
  }
  enlargePhoto() {
    if (this.props.place?.photo) {
      this.props.enlargePhoto(this.props.place.photo);
    }
  }
  onSubmissionDetailsClicked() {
    this.setState((s) => ({
      showSubmissionDetails: s.showSubmissionDetails ? false : true
    }));
  }
  onCopyCoords() {
    const { pushSnackbar, place } = this.props;
    navigator.clipboard
      .writeText([place.y_lat, place.x_lon].join(', '))
      .then(() => {
        pushSnackbar({ type: 'success', message: 'Location copied!' });
      })
      .catch(() => {
        pushSnackbar({ type: 'error', message: 'Failed to access clipboard' });
      });
  }
  onSubmissionRowMouseOver({ rowData }) {
    this.props.onHoverSubmission(rowData);
  }
  onSubmissionRowClicked({ rowData }) {
    this.props.onSelectSubmission(rowData);
  }
  classForSubmissionRow({ index }) {
    const { selectedSubmissionIdx, hoverSubmissionIdx } = this.state;
    const { classes } = this.props;

    if (hoverSubmissionIdx > -1 && hoverSubmissionIdx === index) return classes.submissionsRowHovered;
    if (selectedSubmissionIdx > -1 && selectedSubmissionIdx === index) return classes.submissionsRowSelected;
    return null;
  }
  filterPlacesBySimilarName() {
    this.props.setNameSimilar(this.props.place.place_name);
  }
  render() {
    const { place, classes, theme, onZoomTo, user, onPanTo, nearbyWifi, onHoverWifi, selectedSubmission, pushSnackbar, enlargePhoto, filterOnlyPlacesWithSimilarName } = this.props;
    const { photoFailure, showSubmissionDetails, disableValidationButtons } = this.state;
    if (!place) return null;
    const userCanValidate = has(user.active_features, FEATURES.PLACES_VALIDATION);
    const submissions = this.props.submissions ?? [];
    const numUniqueUsers = new Set(submissions.map((d) => d.user_id)).size;
    let fs, ls;
    if (place.first_seen) {
      const m = moment(place.first_seen);
      if (m.valueOf() > 18000000) {
        fs = m.format('YYYY-MM-DD');
      }
    }
    if (place.last_seen) {
      const m = moment(place.last_seen);
      if (m.valueOf() > 18000000) {
        ls = m.format('YYYY-MM-DD');
      }
    }

    const rowHeight = Math.round(LEFT_PANEL_WIDTH / 1.77);
    const radioEnabled = has(user.active_features, FEATURES.RADIO);
    const submissionsDetailsEnabled = user.is_admin && submissions.length > 1;

    let nearbyWifiContents = null;
    if (nearbyWifi === null) {
      nearbyWifiContents = (
        <Box display="flex" style={{ height: '100%', width: '100%', padding: `${theme.spacing(4)}px 0px` }} alignItems="center" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (nearbyWifi && nearbyWifi.length > 0) {
      nearbyWifiContents = nearbyWifi.map((d) => (
        <WifiPaper style={{ backgroundColor: theme.palette.background.default, marginBottom: theme.spacing(1) }} wifi={d} key={d._id} onHover={onHoverWifi} />
      ));
    } else {
      nearbyWifiContents = null;
    }

    let scrollToSubmission;
    if (selectedSubmission) {
      scrollToSubmission = submissions.indexOf(selectedSubmission);
      if (scrollToSubmission < 0) {
        scrollToSubmission = undefined;
      }
    }

    return (
      <React.Fragment>
        <Box className={classes.buttonContainer}>
          <OverlayedInteractiveIconButton tooltip="Close" icon={<CloseIcon />} onClick={this.props.onClose} />
          {userCanValidate && (
            <>
              <OverlayedInteractiveIconButton
                tooltip="Filter nearby places w/ similar name"
                icon={<FormatSizeIcon />}
                onClick={this.filterPlacesBySimilarName}
                style={{
                  color: filterOnlyPlacesWithSimilarName === place.place_name ? theme.palette.tertiary.main : undefined
                }}
              />
              <OverlayedInteractiveIconButton tooltip="Validate this place" icon={<DoneAllIcon />} onClick={this.onValidationClicked} disabled={disableValidationButtons} />
              <OverlayedInteractiveIconButton
                tooltip="Auto-validate this place"
                icon={<AutorenewIcon />}
                onClick={this.onAutoValidationClicked}
                disabled={disableValidationButtons}
              />
              <OverlayedInteractiveIconButton tooltip="Delete this place" icon={<DeleteIcon />} onClick={this.props.doDelete} disabled={disableValidationButtons} />
            </>
          )}
        </Box>

        {place.photo && !photoFailure ? (
          <Paper className={classes.imgPaper}>
            <img
              onClick={this.enlargePhoto}
              alt="place pov"
              className={classes.img}
              src={place.photo}
              onError={() => {
                this.setState({ photoFailure: true });
              }}
            />
          </Paper>
        ) : (
          <Paper className={classes.imgPaper} />
        )}

        <AppBar position="static">
          <Tabs value={this.state.activeTab} variant="fullWidth" onChange={this.tabChange}>
            <PeanutTab index={0} icon={<StorageIcon />} label="Details" />
            <PeanutTab index={1} icon={<PhotoCameraIcon />} label="Photos" disabled={submissions.length === 0} />
            {radioEnabled && <PeanutTab index={2} icon={<WifiIcon />} label="Wifi" />}
          </Tabs>
        </AppBar>

        <TabPanel value={this.state.activeTab} index={0} dir={theme.direction}>
          <Paper className={classes.tabPaper}>
            <Typography variant={'h4'} title={place.place_name} gutterBottom={true}>
              {place.place_name}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={capitalCase(place.topic)} />
              </ListItem>
              <ListItem>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary={place.gadm_place_name} />
              </ListItem>

              <ListItem button={submissionsDetailsEnabled} onClick={submissionsDetailsEnabled ? this.onSubmissionDetailsClicked : null}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={`${place.num_submissions} Submissions, ${numUniqueUsers} Users`} />
              </ListItem>

              {showSubmissionDetails && (
                <ListItem disableGutters={true} className={classes.expandedListItem}>
                  <AutoSizer>
                    {(dim) => (
                      <Table
                        {...dim}
                        headerHeight={20}
                        rowHeight={30}
                        rowCount={submissions.length}
                        rowGetter={({ index }) => submissions[index]}
                        onRowMouseOver={this.onSubmissionRowMouseOver}
                        onRowClick={this.onSubmissionRowClicked}
                        scrollToIndex={scrollToSubmission}
                        rowClassName={this.classForSubmissionRow}
                        rowStyle={this.rowStyle}
                      >
                        <Column
                          label=""
                          dataKey="photo"
                          width={30}
                          cellRenderer={({ cellData }) => (
                            <img
                              onClick={(e) => {
                                enlargePhoto(cellData);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              className={classes.submissionPhoto}
                              src={cellData}
                            />
                          )}
                        />
                        <Column label="Name" dataKey="name" width={dim.width - 30 - 55 - 55 - 50} />
                        <Column label="A" dataKey="accuracy" width={55} cellDataGetter={({ rowData, dataKey }) => numeral(rowData[dataKey]).format('0.00')} />
                        <Column
                          label=""
                          dataKey="n/a"
                          width={55}
                          cellRenderer={({ rowData }) => (
                            <React.Fragment>
                              <IconButton
                                color="secondary"
                                size="small"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(rowData.sub_id)
                                    .then(() => {
                                      pushSnackbar({ type: 'success', message: 'Submission identifier copied!' });
                                    })
                                    .catch(() => {
                                      pushSnackbar({ type: 'error', message: 'Failed to access clipboard' });
                                    });
                                }}
                              >
                                <AssignmentIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                size="small"
                                onClick={() => {
                                  onPanTo(rowData.x_lon, rowData.y_lat);
                                }}
                              >
                                <ZoomOutMapIcon fontSize="small" />
                              </IconButton>
                            </React.Fragment>
                          )}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </ListItem>
              )}

              <ListItem id="poi-zoom">
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <RoomIcon />
                </ListItemIcon>
                <ListItemText title={[place.y_lat, place.x_lon].join(', ')} primary={[numeral(place.y_lat).format('0.0000'), numeral(place.x_lon).format('0.0000')].join(', ')} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onZoomTo && onZoomTo(place.x_lon, place.y_lat);
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onPanTo(place.x_lon, place.y_lat);
                    }}
                  >
                    <ZoomOutMapIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={this.onCopyCoords}>
                    <AssignmentIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem id="mgrs-coords">
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <ViewColumnIcon />
                </ListItemIcon>
                <ListItemText title={place.mgrs} primary={place.mgrs} />
              </ListItem>
              {fs && (
                <ListItem>
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText primary={fs !== ls ? `${fs} ↔ ${ls}` : fs} />
                </ListItem>
              )}
            </List>
            {this.renderGraphics()}
          </Paper>
        </TabPanel>
        <TabPanel value={this.state.activeTab} index={1} dir={theme.direction}>
          <Box style={{ width: '100%', height: `calc(100% - ${48 + rowHeight}px)` }}>
            <AutoSizer>
              {({ width, height }) => (
                <VirtualList
                  className={classes.disableOutline}
                  height={height}
                  width={width}
                  rowCount={submissions.length}
                  rowHeight={rowHeight}
                  rowRenderer={({ key, index, style }) => (
                    <Box
                      key={key}
                      style={style}
                      onClick={() => {
                        const photos = submissions.map((d) => d.photo);
                        this.props.enlargePhoto([...photos.slice(index), ...photos.slice(0, index)]);
                      }}
                    >
                      <Box className={classes.imgDate}>{moment(submissions[index].timestamp).toString()}</Box>
                      <img className={classes.photo} style={{ width: style.width, height: rowHeight }} alt={submissions[index].sub_id} src={submissions[index].photo} />
                    </Box>
                  )}
                />
              )}
            </AutoSizer>
          </Box>
        </TabPanel>
        <TabPanel value={this.state.activeTab} index={2} dir={theme.direction}>
          {nearbyWifiContents && <Paper className={classes.tabPaper}>{nearbyWifiContents}</Paper>}
        </TabPanel>
      </React.Fragment>
    );
  }
}

DetailPanel.propTypes = {
  // ui / ux
  classes: PropTypes.object.isRequired,
  enlargePhoto: PropTypes.func.isRequired,
  pushSnackbar: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,

  doDelete: PropTypes.func.isRequired,
  hoverSubmission: PropTypes.object,
  id: PropTypes.string,
  nearbyWifi: PropTypes.array,
  nearbyWifiShown: PropTypes.bool,
  onAutoValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onHoverSubmission: PropTypes.func,
  onHoverWifi: PropTypes.func,
  onPanTo: PropTypes.func.isRequired,
  onSelectSubmission: PropTypes.func,
  onValidate: PropTypes.func.isRequired,
  onZoomTo: PropTypes.func,
  place: PropTypes.object,
  selectedSubmission: PropTypes.object,
  setNearbyWifiShown: PropTypes.func,
  submissions: PropTypes.array,
  setNameSimilar: PropTypes.func.isRequired,
  filterOnlyPlacesWithSimilarName: PropTypes.string
};

DetailPanel.defaultProps = {
  submissions: []
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  filterOnlyPlacesWithSimilarName: state.places.filterOnlyPlacesWithSimilarName
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enlargePhoto: enlargePhotoAction,
      pushSnackbar: pushSnackbarAction,
      setNameSimilar: setNameSimilarAction
    },
    dispatch
  );

const withRedux = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default withRedux(withTheme(withStyles(styles)(DetailPanel)));
