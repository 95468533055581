import { combineReducers } from 'redux';
import queryString from 'query-string';
import { configureStore } from '@reduxjs/toolkit';
import { createGLContext } from '@luma.gl/gltools';

// REDUCERS
import demographicsReducer from '../components/demographics/demographicsSlice';
import sentimentReducer from '../components/sentiment/sentimentSlice';
import signalsReducer from '../components/signals/signalsSlice';
import placesReducer from '../components/places/placesSlice';
import rootReducer from '../reducers/index'; // TODO: Root reducer is now a misleading term. Will update as more is implemented.

// SERVICES
import { irisApi } from '../services.js';
import { sentimentApi } from '../components/sentiment/services';
import { placesApi } from '../components/places/services';
import { demographicsApi } from '../components/demographics/services';
import { signalsApi } from '../components/signals/services';
import { radioApi } from '../components/radio/services';

// GLOBALS
const parsedQueryString = queryString.parse(window.location.search);
let hasGPU = false;
let hasGPUHardware = false;
let canvas = document.createElement('canvas');
let gl = createGLContext({
  failIfMajorPerformanceCaveat: true,
  canvas,
  height: 32,
  width: 32,
  throwOnError: false
});

if (gl) {
  gl = null;
  canvas = null;

  hasGPUHardware = true;
  hasGPU = window.localStorage.hasGPU !== 'false';
  window.localStorage.hasGPU = hasGPU;
} else {
  window.localStorage.hasGPU = 'false';
}

// INITIAL APPLICATION STATE -> Passed into configureStore()
const initialState = {
  app: {
    initialQueryString: { ...parsedQueryString },

    productTimer: Date.now(),
    activeProduct: null, // Current View
    enlargePhotoLink: null,

    guide: '',

    hasGPU,
    hasGPUHardware,

    mapboxAccessToken: 'pk.eyJ1Ijoiam9lcmFpaSIsImEiOiJValNfRlY0In0.LZvv1vwL7FH7NAzFDmZFow',
    mapCenter: { x_lon: 10, y_lat: -20 },
    mapPolygon: null,
    mapPolygonBuffered: null /* used by demographics */,
    mapPitch: 50 /* used by demographics */,
    mapBearing: 0 /* used by demographics */,
    mapZoom: 2.0,

    notificationQueue: [],
    palette: window.localStorage.theme || 'dark',
    user: null,
    dropPin: null,
    configPanelOpen: true
  }
};

// EXPORT REDUX STORE
const store = configureStore({
  reducer: combineReducers({
    app: rootReducer,
    places: placesReducer,
    sentiment: sentimentReducer,
    demographics: demographicsReducer,
    signals: signalsReducer,
    [irisApi.reducerPath]: irisApi.reducer // This slice positioning is what redux recommends (a top level slice for the api).
  }),
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([irisApi.middleware, sentimentApi.middleware, demographicsApi.middleware, placesApi.middleware, signalsApi.middleware, radioApi.middleware])
});

export const restoreStateGlobal = (serializedState) => {
  const globalState = serializedState?.state_global?.app_props ?? serializedState.globalState;
  return {
    mapCenter: globalState?.mapCenter ?? initialState.mapCenter,
    mapZoom: globalState?.mapZoom ?? initialState.mapZoom,
    mapBearing: globalState?.mapBearing ?? initialState.mapBearing,
    mapPitch: globalState?.mapPitch ?? initialState.mapPitch
  };
};

export const dumpStateglobal = () => {
  const { app } = store.getState();
  return {
    mapCenter: app.mapCenter,
    mapZoom: app.mapZoom,
    activeProduct: app.activeProduct,
    configPanelOpen: app.configPanelOpen,
    mapPitch: app.mapPitch,
    mapBearing: app.mapBearing
  };
};

export default store;
