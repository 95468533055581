import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

const VideoChart = ({ title, width, data }) => {
  const [idx, setIdx] = useState(0);
  const l = data.length;

  const onPageRight = useCallback(() => {
    if (idx + 1 === l) return;
    setIdx(idx + 1);
  }, [l, idx]);

  const onPageLeft = useCallback(() => {
    if (idx === 0) return;
    setIdx(idx - 1);
  }, [idx]);

  return (
    <Box style={{ width, display: 'flex', flexDirection: 'column' }}>
      <Typography>{title}</Typography>
      <Box key={data[idx]}>
        <video width={width} controls={true}>
          <source src={data[idx]} type="video/mp4" />
        </video>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton size="small" disabled={idx === 0} onClick={onPageLeft}>
          <ArrowLeftIcon />
        </IconButton>
        <IconButton size="small" disabled={idx + 1 === l} onClick={onPageRight}>
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
VideoChart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  width: PropTypes.oneOfType(PropTypes.string, PropTypes.number)
};
export default React.memo(VideoChart);
